import React from "react";
import styled from "styled-components";

const WordSpan = styled.span`
  margin-left: 0.25em;
  margin-right: 0.25em;
  display: inline-block;
`;

/**
 * Split a text into multiple spans so that we can easily
 * detects line breaks afterwards.
 */
const ToSpans: React.FC = ({ children }) => {
  if (typeof children !== "string")
    return <React.Fragment>{children}</React.Fragment>;
  // Here the logic is to:
  // - split the string at every white space and keep the delimiters with ()
  // - discard empty strings
  // - if there's a line break in the string, force an html line break.
  // - render the word only if it's not a delimiter (aka has non-whitespace chars)
  return (
    <React.Fragment>
      {children
        .split(/(\s)/g)
        .filter(Boolean)
        .map((word, index) => (
          <React.Fragment key={index}>
            {word.indexOf("\n") >= 0 ? (
              <br />
            ) : (
              word.match(/\S/) && <WordSpan>{word}</WordSpan>
            )}
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

export default ToSpans;
