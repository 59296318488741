const glsl = (x: TemplateStringsArray) => x.toString();

const ShaderUtils = glsl`
/**
 * Drawing assumed to be from front to back stage (i.e. closest first):
 * (which allows to stop as soon as opacity is 100% or above some threshold like 99.5%)
 */
vec4 composeFTB(vec4 current, vec4 layer) {
  return current + (1.0 - current.a) * vec4(layer.rgb, 1) * layer.a;
}

/**
 * Drawing assumed to be from back to front stage (i.e. closest last):
 */
vec4 composeBTF(vec4 current, vec4 layer) {
  return mix(current, vec4(layer.rgb, 1.0), layer.a);
}

/**
 * Rotate a vector by angle a.
 */
vec2 rotate(vec2 v, float a) {
	float s = sin(a);
	float c = cos(a);
	mat2 m = mat2(c, -s, s, c);
	return m * v;
}

/**
 * pow but for a vec3, component-wise. 
 */
vec3 pow(vec3 a, float b) {
  return vec3(
    pow(a.x, b), 
    pow(a.y, b),
    pow(a.z, b)
  );
}

/**
 * Remap function from Processing.
 */
float remap(float value, float low1, float high1, float low2, float high2) {
    return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
}

float remapClamped(float value, float low1, float high1, float low2, float high2) {
    return clamp(low2 + (high2 - low2) * (value - low1) / (high1 - low1), low2, high2);
}

/**
 * Returns a sinus wave between 0 and 1.
 */
float sin01(float x) {
  return sin(x) * 0.5 + 0.5;
}

/**
 * Clamp between 0 and 1.
 */
float clamp01(float x) {
  return clamp(x, 0.0, 1.0);
}
`;

export default ShaderUtils;
