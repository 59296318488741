import glsl from "./shaderlib/glsl";
import ShaderColorUtils from "./shaderlib/ShaderColorsUtils";
import ShaderNoises from "./shaderlib/ShaderNoises";
import ShaderShapes from "./shaderlib/ShaderShapes";
import ShaderUtils from "./shaderlib/ShaderUtils";

const shader = glsl`
uniform sampler2D u_canvas;
uniform vec2 u_canvas_resolution;
uniform sampler2D u_pic;
uniform vec2 u_pic_resolution;
uniform sampler2D u_dither;
uniform vec2 u_dither_resolution;

uniform float bwMode;
uniform float ditherMode;
uniform float hue;
uniform float animate;
uniform float loopMode;
uniform float axis1;
uniform float axis2;
uniform float axis3;
uniform float axis4;
uniform float axis5;

${ShaderUtils}
${ShaderNoises}
${ShaderShapes}
${ShaderColorUtils}

#define LOOP_TIME 5.0

/**
 * The glitchiness relies on smoothstep's undefined behavior
 * when edge0 >= edge1. So I'd rather have my own version.
 * From: https://thebookofshaders.com/glossary/?search=smoothstep
 */
float mySmoothstep(float edge0, float edge1, float x) {
  if (edge0 == edge1) return 1.0 - edge0;
  float t = clamp((x - edge0) / (edge1 - edge0), 0.0, 1.0);
  return t * t * (3.0 - 2.0 * t);
}

vec2 getNoise1(float t) {
  vec2 noise_uv = gl_FragCoord.xy / iResolution.y;
  return 0.01 * FBMNoise2_iq(noise_uv * vec2(sin(t), cos(t)) * 100.0 / axis2);
}

float getNoise2(float t) {
  vec2 noise_uv = gl_FragCoord.xy / iResolution.y;
  return voroNoise_iq(5.0 * noise_uv + vec2(1.0, -2.0 * t), sin(t * 2.0), 1.0);
}

// 3 loop helpers from:
// https://bjango.com/articles/processingperfectloops/
float timeLoop(float totalframes, float offset) {
  return mod(iTime + offset, totalframes) / totalframes;
}

float tri(float t) {
  return t < 0.5 ? t * 2.0 : 2.0 - (t * 2.0);
}

float inOutSin(float t) {
  return 0.5 - cos(PI * t) / 2.0;
}

void main() {
  // UVs
  vec2 canvas_uv = gl_FragCoord.xy / u_canvas_resolution.xy;
  vec2 noise_uv = gl_FragCoord.xy / iResolution.y;
  vec2 uv = gl_FragCoord.xy / iResolution.xy;

  // Scale and center the picture (cover-fit)
  vec2 pic_uv = gl_FragCoord.xy / iResolution.xy;
  float out_ratio = iResolution.x / iResolution.y;
  float pic_ratio = u_pic_resolution.x / u_pic_resolution.y;
  if (out_ratio > pic_ratio) {
    float scaling = iResolution.x / u_pic_resolution.x;
    pic_uv.y *= pic_ratio / out_ratio;
    pic_uv.y += (u_pic_resolution.y - (u_canvas_resolution.y / scaling)) * 0.5 / u_pic_resolution.y;
  } else {
    float scaling = iResolution.y / u_pic_resolution.y;
    pic_uv.x *= out_ratio / pic_ratio;
    pic_uv.x += (u_pic_resolution.x - (u_canvas_resolution.x / scaling)) * 0.5 / u_pic_resolution.x;
  }

  float t1disp = iTime * 0.3;
  float t2disp = t1disp;
  float t1noise2 = iTime;
  float t2noise2 = iTime;
  if (animate > 0.0 && loopMode > 0.0) {
    t1disp = ((timeLoop(LOOP_TIME, 0.0)));
    t2disp = ((timeLoop(LOOP_TIME, LOOP_TIME / 2.0)));
    t1noise2 = ((timeLoop(LOOP_TIME, 0.0)));
    t2noise2 = ((timeLoop(LOOP_TIME, LOOP_TIME / 2.0)));
  }
  // When not animating, chose a fixed timepoint
  if (animate == 0.0) {
    t1disp = hue * 102.0;
    t1noise2 = hue * 100.0;
  }
  
  // Old loop stuff
  // float t = mod(iTime, LOOP_TIME) / LOOP_TIME;// 0.9235 + cos(iTime) * 0.1;
  // float pointT = sin(PI * iTime);
  // float pointT2 = iTime;

  // Displacement values
  vec2 disp = getNoise1(t1disp);
  if (animate > 0.0 && loopMode > 0.0) {
    vec2 disp2 = getNoise1(t2disp);
    float transition = tri(t2disp);
    disp = mix(disp, disp2, transition);
  }
  disp *= vec2(1.6, 1.0);
  disp *= axis1;

  // VORONOI
  float noise2 = getNoise2(t1noise2);
  if (animate > 0.0 && loopMode > 0.0) {
    float noise2b = getNoise2(t2noise2);
    float transition = tri(t2noise2);
    noise2 = mix(noise2, noise2b, transition);
  }
  if (ditherMode > 0.0) {
    // Dither sampling
    float dither = texture2D(u_dither, gl_FragCoord.xy / u_dither_resolution.xy).x;
    noise2 *= dither;
  }
  // float nDither = clamp01(1.0 * (1.0 - noise2 * noise2) + noise2 * noise2 * dither + 0.0);

  float contrast = 0.7 * axis3;

  vec2 dispSmallText = disp * remapClamped(uv.y, 0.0, 0.4, 0.2, 1.0);
  vec4 canvas1 = texture2D(u_canvas, canvas_uv + dispSmallText * 0.4);
  vec4 canvas2 = texture2D(u_canvas, canvas_uv);
  float canvas3 = (1.0 - mySmoothstep(canvas1.x, canvas2.x, 0.6)) * contrast;
  vec4 canvas = vec4(canvas3, canvas3, canvas3, 1.0);


  vec4 pic1 = texture2D(u_pic, pic_uv);
  // Brightness/contrast formula: f(x) = contrast * (x - 0.5) + 0.5 + brightness
  // From: https://math.stackexchange.com/questions/906240/algorithms-to-increase-or-decrease-the-contrast-of-an-image
  pic1 = axis5 * (pic1 - 0.5) + 0.5 + axis4;
  vec4 pic2 = texture2D(u_pic, pic_uv - disp * 1.2);
  // float pic3 = mySmoothstep(pic1.x, pic2.y, 0.8);
  // vec4 pic4 = vec4(pic3, pic3, pic3, 1.0);

  // This filter relies on differences between color channels, so bw images
  // aren't super well supported... Here's a monkey patch for that:
  // if (abs(pic1.x - pic2.y) < 0.02) {
  //   vec3 bwNoise = normalSimplex3DNoise(noise_uv * vec2(sin(pointT), cos(pointT)) * 10.0 * axis2, 0.01, 1.5 );
  //   float noise3 = voronoi_12(noise_uv * 10.0);
  //   pic2.y += disp.x; //clamp01(pic2.z * noise3);
  // }
  if (abs(pic1.x - pic1.y) < 0.02) {
    // Pretend the red channel is hue for black and white images.
    // (and incidentally bw parts of a color image)
    pic2 = hsv2rgb(pic2.x, 1.0, 1.0).xyzz;
    // Not as cool:
    // pic2.y = clamp01(disp.x + 0.1);
  }

  float pic3 = mySmoothstep(pic1.x, pic2.y, noise2);
  vec4 pic4 = vec4(pic3, pic3, pic3, 1.0);

  if (bwMode > 0.0) {
    gl_FragColor = vec4(vec3(pic4.x * canvas.x > 0.5 ? 1.0 : 0.0), 1.0);
  }
  else {
    // vec3 color = hsv2rgb(sin01(iTime * 0.5), 1.0, 1.0);
    vec3 color = hsv2rgb(hue, 1.0, 1.0);
    // gl_FragColor = vec4(noise2, noise2, noise2, 1.0);
    // gl_FragColor = canvas;
    gl_FragColor = abs(vec4(color, 1.0) - canvas * (contrast + pic4 * (1.0 - contrast)));
    // gl_FragColor = disp.xyyy * 200.0;
    // gl_FragColor = vec4(noise2);
    // gl_FragColor = pic1;
    gl_FragColor.a = 1.0;
  }
}
`;

export default shader;
