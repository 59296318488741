import React, { useState, ChangeEvent, useEffect } from "react";
import { Button, RangeInput, Meter, RadioButtonGroup, Text } from "grommet";
import { getShaderDoodleCanvas, resetShaderDoodleTime } from "../utils/utils";
import FileSaver from "file-saver";
import Checkable from "./Checkable";

type Props = {
  name?: string;
  /**
   * Called just before the capture is initiated and started.
   */
  prepareRecording: () => void;
  startRecording: (duration: number) => void;
  startAnimation: () => void;
  onStopRecording: () => void;
  readyForAnimation: boolean;
  isRecording: boolean;
};

function canUseWebP() {
  var elem = document.createElement("canvas");
  if (!!(elem.getContext && elem.getContext("2d"))) {
    // was able or not to get WebP representation
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  }
  // very old browser like IE 8, canvas not supported
  return false;
}
const webpSupported = canUseWebP();

const CanvasExporter: React.FC<Props> = ({
  name,
  prepareRecording,
  startAnimation,
  startRecording,
  onStopRecording,
  readyForAnimation,
  isRecording,
}) => {
  const [targetDuration, setTargetDuration] = useState(10);
  const [frameRate, setFrameRate] = useState(60);
  const [frameProgress, setFrameProgress] = useState(0);
  const [recordCount, setRecordCount] = useState(0);

  /**
   * sending readyForAnimation=true is the signal to trigger the animation.
   */
  useEffect(() => {
    if (!readyForAnimation) return;
    if (isRecording) return;
    // Don't re-record
    if (recordCount > 0) return;

    // Capture doesn't support import module and its NPM build is broken,
    // so get it from the browser JS include.
    const CCapture = (window as any).CCapture;
    const capturer =
      (window as any).capturer ||
      new CCapture({
        format: "webm",
        framerate: frameRate,
        quality: 60,
        name: name || "video",
        verbose: true,
        timeLimit: targetDuration,
      });
    console.log(capturer);
    // Expose the capturer to stop it later.
    (window as any).capturer = capturer;
    const canvas = getShaderDoodleCanvas();
    // Use a local frame count because React state probably won't be
    // updated as fast.
    let frameCount = 0;
    function render() {
      // Usually we would render something here, but we let the other
      // (shader-doodle) requestAnimationFrame do the iTime etc. update
      // in parallel. The order between rAF calls should be respected by
      // the browser.
      capturer.capture(canvas);
      // Detect the end of the recording to update the UI accordingly.
      if (frameCount >= Math.ceil(frameRate * targetDuration)) {
        onStopRecording();
        setRecordCount((c) => c + 1);
        frameCount = 0;
        setFrameProgress(0);
        // clearInterval(interval);
      } else {
        // Update our progress UI.
        frameCount += 1;
        setFrameProgress(frameCount);
        requestAnimationFrame(render);
      }
    }
    render();
    resetShaderDoodleTime();
    capturer.start();
    // const interval = setInterval(() => console.log("go"), 1000);
    // TODO stop requestAF
    // (window as any).capturing = true;
    startRecording(targetDuration);
  }, [
    readyForAnimation,
    isRecording,
    recordCount,
    frameRate,
    name,
    targetDuration,
    startRecording,
    onStopRecording,
  ]);

  return (
    <React.Fragment>
      {!webpSupported ? (
        <Text>Use Chrome to export videos.</Text>
      ) : recordCount > 0 && !isRecording ? (
        <Button
          label="RELOAD PAGE 🎬"
          margin={{ top: "small", right: "small" }}
          onClick={() => window.location.reload()}
        />
      ) : !isRecording ? (
        <React.Fragment>
          {targetDuration} seconds
          <RangeInput
            min={1}
            max={60}
            value={targetDuration}
            onChange={(event: any) => setTargetDuration(event.target.value)}
          />
          <RadioButtonGroup
            name="fps"
            options={[
              { label: "30 FPS", value: 30 },
              { label: "60 FPS", value: 60 },
            ]}
            value={frameRate}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setFrameRate(parseInt(event.target.value))
            }
            children={Checkable}
          />
          <Button
            label="VIDEO"
            margin={{ top: "small", right: "small" }}
            onClick={() => {
              prepareRecording();
            }}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Text>
            {targetDuration}s at {frameRate} FPS
          </Text>
          <Meter
            background="#E0E0E0"
            values={[
              {
                value: frameProgress / (frameRate * targetDuration),
                label: "Export progress",
                color: "brand",
              },
            ]}
            max={1}
            aria-label="video export progress"
            thickness="10px"
          />
          <Button
            label="STOP & DOWNLOAD"
            margin={{ top: "small", right: "small" }}
            onClick={() => {
              const capturer = (window as any).capturer;
              capturer.stop();
              capturer.save();
              onStopRecording();
              setRecordCount((c) => c + 1);
            }}
          />
        </React.Fragment>
      )}
      <Button
        label="IMAGE"
        margin={{ vertical: "small", right: "small" }}
        onClick={() => {
          getShaderDoodleCanvas()?.toBlob((blob) => {
            if (!blob) {
              console.error("Failed to export blob from canvas.");
              return;
            }
            const filename = (name || "image-" + Date.now()) + ".png";
            FileSaver.saveAs(blob, filename);
          });
        }}
      />
    </React.Fragment>
  );
};

export default CanvasExporter;
