import React from "react";
import { EventData } from "../types/Parameters";
import styled from "styled-components";
import moment from "moment";

const StyledTable = styled.table`
  text-align: left;
  border: none;
  border-collapse: collapse;
  border-width: 0px;
  color: white;
  width: 100%;
  font-weight: normal;

  tr {
    width: 100%;
  }

  tbody > tr {
  }

  td,
  th {
    position: relative;
    padding: 2px 1vw;
    white-space: nowrap;
  }

  tbody > tr:hover {
    background-color: white;
    color: #0000ff;
  }

  tr.strikeout td:before {
    content: " ";
    position: absolute;
    top: 10px;
    left: 0;
    border-bottom: 1px solid white;
    width: 100%;
  }
`;

type Props = {
  events: EventData[];
  selectedEventId?: string;
  onEventSelected: (event: EventData) => void;
};

const EventTable: React.FC<Props> = ({
  events,
  onEventSelected,
  selectedEventId,
}) => {
  const tomorrow = moment().add(-1, "d").unix();

  return (
    <StyledTable>
      <thead>
        <tr>
          <th>
            <span>&nbsp;&nbsp;</span>Date
          </th>
          <th>Name</th>
          <th>Serie</th>
          <th>Venue</th>
          <th>Opening</th>
          <th>Closing</th>
          <th>Prog.</th>
        </tr>
      </thead>
      <tbody>
        {events.map((event) => (
          <tr
            key={event.eventId}
            onClick={() => onEventSelected(event)}
            className={event.dateTimestamp < tomorrow ? "strikeout" : undefined}
          >
            <td>
              {event.eventId === selectedEventId ? (
                "> "
              ) : (
                <span>&nbsp;&nbsp;</span>
              )}
              {event.date}
            </td>
            <td>{event.title}</td>
            <td>{event.serie}</td>
            <td>{event.venue}</td>
            <td>{event.timeStart}</td>
            <td>{event.timeEnd}</td>
            <td>{event.program}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default EventTable;
