import React from "react";
import styled from "styled-components";
import Decorator01 from "../decorators/Decorator01";
import LayoutConfig, { LayoutItemType } from "../types/LayoutConfig";
import Parameters from "../types/Parameters";
import { getDayMonthStr } from "../utils/utils";
import { ReactComponent as DateIcon } from "./icons/Date.svg";
import { ReactComponent as PartyEnd } from "./icons/End.svg";
import { ReactComponent as SerieIcon } from "./icons/Genre.svg";
import { ReactComponent as VenueIcon } from "./icons/Place.svg";
import { ReactComponent as PartyStart } from "./icons/Start.svg";
import LayoutWrapper from "./LayoutWrapper";
import ToSpans from "./ToSpans";

type StyledProps = {
  width?: number;
  heigth?: number;
};

const Container = styled.div<StyledProps>`
  position: absolute;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: ${(props) =>
    props.width && props.heigth && props.width <= props.heigth
      ? "3%"
      : "3% 6%"};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MainTitle = styled.div<StyledProps>`
  font-family: "Sans Chocs";
  font-size: 250px;
  line-height: 0.82;
  font-weight: bold;
  text-align: center;
  width: 100%;
  max-width: 100%;
  /* Compensate for the margin added by ToSpans */
  margin: 60px -0.25em;
  overflow: hidden;
  align-self: center;
  /* Keep a bit of flex-grow so that the smaller-than-one line-height 
  doesn't cause the paper fitBounds to make the text smaller than it should be */
  flex-grow: ${(props) =>
    props.width && props.heigth && props.width < props.heigth ? "0.1" : "1"};
  /* Prevent the title from pushing InfoContainer to the bottom. */
  overflow-y: hidden;
`;

const InfoContainer = styled.div`
  align-self: flex-start;
  flex-shrink: 0;

  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
  max-width: 100%;

  padding: 0;
  margin: 0;
  font-size: 41px;
  /* Necessary on Chrome to keep the row height in check */
  line-height: 0.82;
  font-family: "TGR Mono";

  & > * {
    width: fit-content;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    > span {
      height: auto;
      width: auto;
      margin: 7px 15px 2px 15px;
      /* Quick fix: Needed in width 1080 with long name */
      white-space: nowrap;
    }
    svg {
      margin: 0px;
      width: 62px;
      height: 62px;
    }
  }

  .li-dateRect {
    grid-row: 1 / span 2;
    grid-column-start: 2;

    span {
      text-align: center;
      display: inline-flex;
      flex-flow: column;
      align-items: center;
    }
  }
  .li-dateD,
  .li-dateM {
    width: min-content;
  }

  .li-iconTimeEndRect {
    grid-column-start: 3;
  }
`;

// Formula found with https://www.desmos.com/calculator
const titleFontSize = (charCount: number) => {
  return 290 - 35 * Math.sqrt(0.3 * charCount);
};

type Props = {
  parameters: Parameters;
  debug?: boolean;
  refToLayout?: any;
};

const PosterLayout02: React.FC<Props> = ({
  parameters,
  debug,
  refToLayout,
}) => {
  // const m = getMoment(parameters.date);
  const { day, month } = getDayMonthStr(parameters.date);
  const serieDisplay = parameters.serie ? undefined : "none";
  return (
    <LayoutWrapper
      debug={debug}
      canvasWidth={parameters.width}
      canvasHeight={parameters.height}
      refToLayout={refToLayout}
    >
      <Container width={parameters.width} heigth={parameters.height}>
        <MainTitle
          className="li-title"
          data-words-to-spans
          data-fit-bounds
          // The dynamic font size also enable users to add whitespace
          // to reduce the font size.
          style={{ fontSize: titleFontSize(parameters.title.length) }}
          width={parameters.width}
          heigth={parameters.height}
        >
          <ToSpans>{parameters.title}</ToSpans>
        </MainTitle>
        <InfoContainer>
          <span className="li-datePrefixRect">
            <DateIcon />
          </span>
          <span className="li-dateRect">
            <span>
              <span className="li-dateD">{day}</span>
              <span className="li-dateM">{month}</span>
            </span>
          </span>
          <span className="li-iconTimeStartRect">
            <PartyStart />
          </span>
          <span className="li-timeStartRect">
            <span className="li-timeStart">{parameters.timeStart}</span>
          </span>
          <span className="li-iconVenueRect">
            <VenueIcon />
          </span>
          <span className="li-venueRect">
            <span className="li-venue">{parameters.venue}</span>
          </span>
          <span className="li-iconTimeEndRect">
            <PartyEnd style={{ gridColumnStart: 3 }} />
          </span>
          <span className="li-timeEndRect">
            <span className="li-timeEnd">{parameters.timeEnd}</span>
          </span>
          <span className="li-iconSerieRect" style={{ display: serieDisplay }}>
            <SerieIcon style={{ display: serieDisplay }} />
          </span>
          <span className="li-serieRect" style={{ display: serieDisplay }}>
            <span className="li-serie" style={{ display: serieDisplay }}>
              {parameters.serie}
            </span>
          </span>
        </InfoContainer>
      </Container>
    </LayoutWrapper>
  );
};

export const layout02Config: LayoutConfig = {
  items: [
    { name: "title", type: LayoutItemType.Text },
    { name: "dateD", type: LayoutItemType.Text },
    { name: "dateM", type: LayoutItemType.Text },
    { name: "dateRect", type: LayoutItemType.DummyRect },
    { name: "timeStart", type: LayoutItemType.Text },
    { name: "timeStartRect", type: LayoutItemType.DummyRect },
    { name: "timeEnd", type: LayoutItemType.Text },
    { name: "timeEndRect", type: LayoutItemType.DummyRect },
    { name: "venue", type: LayoutItemType.Text },
    { name: "venueRect", type: LayoutItemType.DummyRect },
    { name: "serie", type: LayoutItemType.Text },
    { name: "serieRect", type: LayoutItemType.DummyRect },
    { name: "program", type: LayoutItemType.Text },
    { name: "datePrefixRect", type: LayoutItemType.DummyRect },
    { name: "iconTimeStartRect", type: LayoutItemType.DummyRect },
    { name: "iconTimeEndRect", type: LayoutItemType.DummyRect },
    { name: "iconVenueRect", type: LayoutItemType.DummyRect },
    { name: "iconSerieRect", type: LayoutItemType.DummyRect },
  ],
  decoratorConfigs: [
    {
      decorator: Decorator01,
      target: "dateRect",
      config: { vs: 0, hs: 0 },
    },
    {
      decorator: Decorator01,
      target: "timeStartRect",
      config: { vs: 0, hs: 0 },
    },
    {
      decorator: Decorator01,
      target: "timeEndRect",
      config: { vs: 0, hs: 0 },
    },
    {
      decorator: Decorator01,
      target: "venueRect",
      config: { vs: 0, hs: 0 },
    },
    {
      decorator: Decorator01,
      target: "serieRect",
      config: { vs: 0, hs: 0 },
    },
    {
      decorator: Decorator01,
      target: "programRect",
      config: { vs: 0, hs: 0 },
    },
    {
      decorator: Decorator01,
      target: "datePrefixRect",
      config: { vs: 0, hs: 0 },
    },
    {
      decorator: Decorator01,
      target: "iconTimeStartRect",
      config: { vs: 0, hs: 0 },
    },
    {
      decorator: Decorator01,
      target: "iconTimeEndRect",
      config: { vs: 0, hs: 0 },
    },
    {
      decorator: Decorator01,
      target: "iconVenueRect",
      config: { vs: 0, hs: 0 },
    },
    {
      decorator: Decorator01,
      target: "iconSerieRect",
      config: { vs: 0, hs: 0 },
    },
  ],
};

export default PosterLayout02;
