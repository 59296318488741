import * as paper from "paper";
import Decorator from "./Decorator";

const defaultConfig = {
  hs: 12,
  vs: 12,
  strokeWidth: 3,
};
type Decorator01Config = typeof defaultConfig;

class Decorator01 extends Decorator {
  border: paper.Path.Rectangle;
  config = defaultConfig;

  constructor(
    item: paper.Item,
    configOverrides: Partial<Decorator01Config> = {}
  ) {
    super(item);
    Object.assign(this.config, configOverrides);
    this.border = new paper.Path.Rectangle(paper.view.bounds);
    this.border.strokeColor = new paper.Color("black");
    this.border.strokeWidth = this.config.strokeWidth;
    this.update();
  }

  update = () => {
    const b = this.item.bounds;
    this.border.visible =
      b.area > 0 && (this.item.visible || this.item.data.forceDecorate);
    this.border.bounds = new paper.Rectangle(
      b.topLeft.subtract(new paper.Point(this.config.hs, this.config.vs)),
      b.size.add(new paper.Size(this.config.hs * 2, this.config.vs * 2))
    );
  };
}

export default Decorator01;
