import Papa from "papaparse";
import { useEffect, useState } from "react";
import LoadStatus from "../types/LoadStatus";
import {
  CACEventType,
  computeEventId,
  computeTimestamp,
  EventData,
} from "../types/Parameters";

const getTime = (input: string) => {
  let t = (input as string) || "";
  if (t.length === 7) t = "0" + t;
  if (t.length > 5) t = t.substr(0, 5);
  if (t.length === 0) t = "--:--";
  return t;
};

const useCSVSource = (url: string | undefined | null) => {
  const [status, setStatus] = useState(LoadStatus.NONE);
  const [data, setData] = useState([] as EventData[]);

  useEffect(() => {
    if (!url) return;

    Papa.parse("https://cors-anywhere-nv.herokuapp.com/" + url, {
      download: true,
      header: true,
      complete: function (results) {
        const data = (results.data as { [key: string]: any }[]).map((row) => {
          const night: EventData = {
            eventId: "tmp",
            title: row["Titre"],
            organizer: row["Organisateur"],
            date: row["Date"],
            dateTimestamp: 0,
            serie: row["Série"],
            eventType:
              row["Type"] === "Concert"
                ? CACEventType.Concert
                : CACEventType.Party,
            venue: row["Lieu"],
            timeStart: getTime(row["Ouverture"] || ""),
            timeEnd: getTime(row["Fermeture"] || ""),
            with: row["Avec"],
            program: row["Prog."],
          };
          night.eventId = computeEventId(night);
          night.dateTimestamp = computeTimestamp(night.date);
          return night;
        });
        setData(data);
        setStatus(LoadStatus.SUCCESS);
      },
      error: (error) => {
        setStatus(LoadStatus.ERROR);
        console.error("Couldn't get Google Sheets.", error);
      },
    });
    setStatus(LoadStatus.LOADING);
  }, [url]);

  return { status, data };
};

export default useCSVSource;
