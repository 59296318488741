import styled from "styled-components";

const DropZone = styled.div`
  margin: 16px;
  padding: 30px;
  border: dashed 1px #ff0000;
  text-align: center;
`;

export default DropZone;
