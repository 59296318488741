import React from "react";
import useCSVSource from "./data/useSheets";
import LoadStatus from "./types/LoadStatus";
import { Text, DropButton } from "grommet";
import Parameters, { computeParameters } from "./types/Parameters";
import EventTable from "./components/EventTable";
import styled from "styled-components";

const StyledDrop = styled.div`
  background-color: #0000ff88;
  min-width: 100vw;
  width: 100%;
`;

const StyledDropButton = styled(DropButton)`
  &,
  &:hover,
  &:focus {
    border-color: transparent;
    box-shadow: none;
  }
  &:hover {
    text-decoration: underline;
  }
  color: black;
  /* To align with other title: */
  margin-top: -8px;
  padding-top: 7px;
`;

type Props = {
  eventId?: string;
  setParameters: React.Dispatch<React.SetStateAction<Parameters>>;
  csvURL: string | null;
  open: boolean;
  setOpen: (o: boolean) => void;
};

const CSVParamControl: React.FC<Props> = (props) => {
  // Data source
  const sheets = useCSVSource(props.csvURL);

  if (!props.csvURL) return <Text>Locked content</Text>;
  if (sheets.status === LoadStatus.LOADING) return <Text>Loading data...</Text>;
  if (sheets.status === LoadStatus.ERROR)
    return <Text>Error: Failed to fetch and parse CSV data.</Text>;

  return (
    <React.Fragment>
      <StyledDropButton
        open={props.open}
        onClose={() => props.setOpen(false)}
        onOpen={() => props.setOpen(true)}
        label={<span>{props.open ? "[x] CLOSE" : "[ ] OPEN"} EVENTS</span>}
        dropAlign={{ bottom: "top" }}
        dropProps={
          {
            elevation: "none",
            style: { backgroundColor: "transparent" },
          } as any
        }
        dropContent={
          <StyledDrop>
            <EventTable
              events={sheets.data}
              selectedEventId={props.eventId}
              onEventSelected={(event) => {
                // A bit dirty, but right now Parameters has all the fields of EventData.
                // Also make sure to not use Parameters to avoid re-rendering the table
                // each time parameters change.
                props.setParameters((params) =>
                  computeParameters(Object.assign({}, params, event))
                );
              }}
            />
          </StyledDrop>
        }
      />
    </React.Fragment>
  );
};

export default CSVParamControl;
