import * as paper from "paper";

class Decorator {
  item: paper.Item;
  config: { [key: string]: any } = {};

  constructor(item: paper.Item, ..._args: any) {
    this.item = item;
  }

  update = () => {};
}

export default Decorator;
