import * as paper from "paper";

import Decorator from "./decorators/Decorator";
import LayoutConfig, { LayoutItemType } from "./types/LayoutConfig";
import Parameters from "./types/Parameters";

type Item =
  | {
      type: LayoutItemType.SVG;
      node: SVGElement;
      item: paper.Item;
    }
  | {
      type: LayoutItemType.Text;
      item: paper.PointText;
    }
  | {
      type: LayoutItemType.DummyRect;
      item: paper.Item;
    };

class PaperMain {
  background: paper.Shape.Rectangle;
  items: { [key: string]: Item } = {};
  decorators: Decorator[] = [];
  layoutConfig: LayoutConfig;
  layoutNode: HTMLElement;

  constructor(
    canvas: HTMLCanvasElement | string,
    layoutNode: HTMLElement,
    layoutConfig: LayoutConfig
  ) {
    this.layoutNode = layoutNode;
    this.layoutConfig = layoutConfig;
    paper.setup(canvas);

    this.background = new paper.Shape.Rectangle(paper.view.bounds);
    this.background.fillColor = new paper.Color("#ffffff");

    for (let item of layoutConfig.items) {
      switch (item.type) {
        case LayoutItemType.Text:
          this.items[item.name] = {
            type: item.type,
            item: new paper.PointText({
              point: [0, 0],
              selected: false,
            }),
          };
          break;
        case LayoutItemType.DummyRect:
          this.items[item.name] = {
            type: item.type,
            item: new paper.Path.Rectangle({
              fillColor: "transparent",
              selected: false,
              size: [10, 10],
            }),
          };
          break;
        default:
          break;
      }
    }
    const svgElements = layoutNode.getElementsByTagName("svg");
    for (let i = 0; i < svgElements.length; i++) {
      const node = svgElements[i];
      const item = paper.project.importSVG(node);
      // item.fitBounds(new paper.Rectangle(new paper.Point))
      this.items["svg_" + i] = {
        type: LayoutItemType.SVG,
        item: item,
        node,
      };
    }
    for (let dc of layoutConfig.decoratorConfigs) {
      if (dc.target && this.items[dc.target]) {
        const d = new dc.decorator(this.items[dc.target].item, dc.config);
        this.decorators.push(d);
      }
    }
    // Dirty fix to make sure the UI is updated once font are loaded
    // In case the font-loaded event arrives before paper.js is initialized.
    // It seems paper has its own font loading delay.
    // Especially on Chrome, where we need to make a fake movement to
    // actually trigger a redraw.
    const fakePaperUpdate = () => {
      this.background.translate(new paper.Point(1, 0));
      this.background.translate(new paper.Point(-1, 0));
      (paper.view as any).draw();
    };
    setTimeout(fakePaperUpdate, 100);
    setTimeout(fakePaperUpdate, 400);
    setTimeout(fakePaperUpdate, 1000);
  }

  getNodeText = (node: Element) => {
    if (node.getAttribute("data-words-to-spans") && node.children.length > 0) {
      let text = "";
      let lastH = -100000;
      let i = 0;
      do {
        if (node.tagName === "BR") text += "\n";
        else {
          let h = node.children[i].getBoundingClientRect().top;
          if (i > 0 && h !== lastH) text += "\n";
          else text += " ";
          text += node.children[i].textContent || "";
          lastH = h;
        }
        i += 1;
      } while (i < node.children.length);
      return text.trim();
    } else return node.textContent || "";
  };

  applyTextProperties = (text: paper.PointText, node: Element) => {
    const properties = window.getComputedStyle(node);
    text.fontFamily = properties.fontFamily;
    text.fontWeight = properties.fontWeight;
    text.fontSize = properties.fontSize;
    // There's a small offset in fontsize between HTML and Paper it seems.
    // It's especially visible with long texts inside border-boxes, where it
    // looks like there's a big margin at the end of the text.
    // UPDATE: Actually don't, this offset is on Firefox and not Chrome...
    // text.scaling = new paper.Point(1.07, 1.07);
    text.fillColor = new paper.Color(
      properties.color ? properties.color.toString() : "black"
    );
    text.justification = properties.textAlign;
    text.leading = parseFloat(properties.lineHeight);
    // Also copy the content, since it's the layout that is responsible for
    // adding characters
    text.content = this.getNodeText(node);
  };

  applyPosition = (item: paper.Item, node: Element) => {
    const doFitBounds = node.getAttribute("data-fit-bounds");
    const rect = node.getBoundingClientRect();
    const topLeft = new paper.Point(rect.left, rect.top);
    if (doFitBounds && item.bounds.area > 0) {
      item.fitBounds(
        new paper.Rectangle(topLeft, new paper.Size(rect.width, rect.height))
      );
    } else {
      item.bounds.topLeft = topLeft;
    }
  };

  applySize = (item: paper.Item, node: Element) => {
    const rect = node.getBoundingClientRect();
    if (rect.width * rect.height === 0) {
      item.visible = false;
      return;
    }
    item.visible = true;
    item.bounds.width = rect.width;
    item.bounds.height = rect.height;
  };

  update = (params: Parameters) => {
    for (let name in this.items) {
      const item = this.items[name];
      switch (item.type) {
        case LayoutItemType.SVG:
          this.applyPosition(item.item, item.node);
          this.applySize(item.item, item.node);
          break;
        case LayoutItemType.Text:
          const coll = this.layoutNode.getElementsByClassName("li-" + name);
          if (coll.length > 0) {
            item.item.visible = true;
            this.applyTextProperties(item.item, coll[0]);
            // Adjust position last, because other properties can affect it in
            // Paper.
            this.applyPosition(item.item, coll[0]);
          } else item.item.visible = false;
          break;
        case LayoutItemType.DummyRect:
          const coll2 = this.layoutNode.getElementsByClassName("li-" + name);
          if (coll2.length > 0) {
            this.applySize(item.item, coll2[0]);
            this.applyPosition(item.item, coll2[0]);
          }
          break;
      }
    }

    for (let d of this.decorators) {
      d.update();
    }

    this.background.bounds = paper.view.bounds;
    if (params.drawLayout) this.background.sendToBack();
    else this.background.bringToFront();

    (paper.view as any).draw();
  };

  /**
   * Needed because paper.js can't do it alone sometimes
   * for no reason...
   *
   * @param w new canvas/view width
   * @param h new canvas/view height
   */
  updateSize = (w: number, h: number) => {
    paper.view.viewSize = new paper.Size(w, h);
  };
}

export default PaperMain;
